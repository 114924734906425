import { gql } from '@apollo/client'

export const ATTENDANCE_FLAG_MUTATION = gql`
  mutation($input: confirmEventAttendanceInput!) {
    confirmEventAttendance(input: $input) {
      id
      not_attending
    }
  }
`
