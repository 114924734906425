import { gql } from '@apollo/client'

import {
  GUEST_INVITATION_FIELDS_FRAGMENT,
  PARENTS_MEETING_BOOKING_FIELDS_FRAGMENT,
  PARENTS_MEETING_EVENT_FIELDS_FRAGMENT
} from '../fragments'

export const PARENTS_MEETING_BOOKING_QUERY = gql`
  ${GUEST_INVITATION_FIELDS_FRAGMENT}
  ${PARENTS_MEETING_BOOKING_FIELDS_FRAGMENT}
  ${PARENTS_MEETING_EVENT_FIELDS_FRAGMENT}

  query ($eventId: ID!) {
    event(id: $eventId) {
      ...EventFields

      bookings {
        ...BookingFields
      }
    }

    guestInvitations(event_id: $eventId) {
      ...GuestInvitationFields
    }
  }
`

export const PARENTS_MEETING_BOOKING_EVENTS_LIST_QUERY = gql`
  {
    events(limit: 50, orderBy: [{ sessions: { aggregate: MIN, column: STARTS_AT }, order: DESC }]) {
      id
      title
      description
      start_date
      end_date
    }
  }
`

/**
 * REFRESH AVAILABLE GROUP SLOT COUNT
 */
export const PARENTS_MEETING_BOOKING_REFRESH_AVAILABLE_SLOT_COUNT_QUERY = gql`
  query ($groupId: ID!) {
    group: group(id: $groupId) {
      id
      availableSlotCount
    }
  }
`

export const PARENTS_MEETING_BOOKING_AVAILABLE_SLOTS_QUERY = gql`
  query ($groupId: ID!) {
    group(id: $groupId) {
      id
      availableSlotCount
      title
      slots(available: true) {
        id
        starts_at
        ends_at
        capacity
        remaining
      }
    }
  }
`
