import React, { useEffect, useRef, useState } from 'react'
import { Button, Typography } from 'antd'
import { CustomModal, EmptyMessage, Loader, SimpleCard, VideoConferenceTimer } from '@components'
import { JitsiMeeting } from '@jitsi/react-sdk'
import { JITSI_CREATE_LOG_MUTATION } from '@graphql'
import { getLessonSubject, getRoomName, getSanitizedName, getStudentFullname, toDayJs } from '@helpers'
import { ReactComponent as VideoConference } from '@assets/video-conference.svg'
import { useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isToday from 'dayjs/plugin/isToday'
import PropTypes from 'prop-types'

import './VideoConferenceModal.less'

dayjs.extend(advancedFormat)
dayjs.extend(isToday)

const VideoConferenceModal = ({ bookingSlot, event, group, handleCancel, isGuest = false, lesson, slot, student, username }) => {
  const [loading, setLoading] = useState(true)
  const [jitsiApi, setJitsiApi] = useState(null)
  const [hasExpired, setHasExpired] = useState(dayjs().isAfter(toDayJs(slot.ends_at)))
  const { vc_auto_disconnect: autoDisconnect } = event
  const startTime = toDayJs(slot.starts_at)
  const endTime = toDayJs(slot.ends_at)
  const log = useRef([])

  // Just log errors - we don't want errors to block the user experience
  const [createJitsiLog] = useMutation(JITSI_CREATE_LOG_MUTATION, {
    onError: (error) => console.error("Error creating Jitsi log", error)
  })

  useEffect(() => {
    return () => {
      if (log.current.length > 0) {
        createJitsiLog({ variables: { input: { is_guest: isGuest, log_entries: log.current } } }) // eslint-disable-line
      }
    }
  }, [createJitsiLog, isGuest])

  const handleUpdateLog = (type, roomId, payload) => {
    log.current.push({ type, event_id: event.id, room_id: roomId, booking_slot_id: bookingSlot?.id, payload })
  }

  const handleAutoDisconnect = () => {
    setHasExpired(true)

    if (jitsiApi) {
      hangup()
    }
  }

  const handleJitsiReady = api => {
    setJitsiApi(api)

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const handleCloseModal = () => {
    if (jitsiApi) {
      jitsiApi.dispose()
    }

    handleCancel()
  }

  const hangup = () => {
    jitsiApi.executeCommand('hangup')
    handleCloseModal()
  }

  const expiredAutoDisconnect = hasExpired && autoDisconnect
  const isToday = startTime.isToday()
  const roomName = getRoomName(slot.id)

  return (
    <CustomModal
      className={`sb-video-conference-modal`}
      closable
      centered
      visible={true}
      width='100%'
      onCancel={handleCloseModal}
      maskClosable={false}
      footer={null}
      title={
        <div>
          <Typography.Title level={3} color='white'>
            {getStudentFullname(student)}
          </Typography.Title>

          <Typography.Paragraph color='white'>{group.title}</Typography.Paragraph>

          <Typography.Paragraph color='white'>{getLessonSubject(lesson)}</Typography.Paragraph>

          {!isToday && (
            <div className='sb-video-conference-modal__date'>
              <Typography.Paragraph color='white'>{startTime.format('dddd Do MMMM YYYY')}</Typography.Paragraph>

              <Typography.Text color='white' strong>
                {startTime.format('H:mma')} - {endTime.format('H:mma')}
              </Typography.Text>
            </div>
          )}

          {isToday && <VideoConferenceTimer handleAutoDisconnect={handleAutoDisconnect} startTime={startTime} endTime={endTime} autoDisconnect={autoDisconnect} />}
        </div>
      }
    >
      {!expiredAutoDisconnect && loading && <Loader message='Connecting to video conference....' />}

      {!expiredAutoDisconnect && (
        <div style={{ height: '100%', display: loading ? 'none' : 'block' }}>
          <JitsiMeeting
            domain='meet.schoolbooking.com'
            roomName={roomName}
            userInfo={{
              displayName: getSanitizedName(username)
            }}
            interfaceConfigOverwrite={{
              TOOLBAR_BUTTONS: [
                'microphone',
                'camera',
                'closedcaptions',
                'desktop',
                'fullscreen',
                'fodeviceselection',
                'hangup',
                'profile',
                'chat',
                'livestreaming',
                'etherpad',
                'settings',
                'raisehand',
                'videoquality',
                'filmstrip',
                'feedback',
                'stats',
                'shortcuts',
                'tileview',
                'download',
                'help',
                'mute-everyone'
              ]
            }}
            configOverwrite={{
              disableDeepLinking: true,
              hideConferenceSubject: true
            }}
            getIFrameRef={iframeRef => {
              iframeRef.style.height = '100%'
            }}
            onReadyToClose={() => handleCloseModal()}
            onApiReady={(api) => {
              api.on("cameraError", (payload) => {
                handleUpdateLog("CAMERA_ERROR", roomName, payload)
              }); 
            
              api.on("micError", (payload) => {
                handleUpdateLog("MIC_ERROR", roomName, payload)
              }); 

              api.on("deviceListChanged", (payload) => {
                handleUpdateLog("DEVICE_LIST_CHANGED", roomName, payload)
              }); 

              api.on("audioAvailabilityChanged", (payload) => {
                handleUpdateLog("AUDIO_AVAILABILITY_CHANGED", roomName, payload)
              }); 
            
              api.on("videoAvailabilityChanged", (payload) => {
                handleUpdateLog("VIDEO_AVAILABILITY_CHANGED", roomName, payload)
              });

              api.on("videoConferenceJoined", (payload) => {
                handleUpdateLog("VIDEO_CONFERENCE_JOINED", roomName, payload)
              })

              api.on("videoConferenceLeft", (payload) => {
                handleUpdateLog("VIDEO_CONFERENCE_LEFT", roomName, payload)
              })

              handleJitsiReady(api)
            }}
          />
        </div>
      )}

      {expiredAutoDisconnect && (
        <SimpleCard color='quaternary'>
          <EmptyMessage title='Appointment Expired' image={<VideoConference />}>
            <div className='sb-video-conference-modal__expired-text'>
              <Typography.Paragraph color='white'>This appointment has expired.</Typography.Paragraph>
              <Typography.Paragraph color='white'>
                This call was set to auto disconnect after its alloted time.
              </Typography.Paragraph>
            </div>

            <Button type='primary' className='cta' shape='round' size='large' onClick={() => handleCloseModal()}>
              Back to Bookings
            </Button>
          </EmptyMessage>
        </SimpleCard>
      )}
    </CustomModal>
  )
}

VideoConferenceModal.propTypes = {
  bookingSlot: PropTypes.object,
  handleCancel: PropTypes.func,
  isGuest: PropTypes.bool,
  slot: PropTypes.object,
  username: PropTypes.string
}

export default VideoConferenceModal