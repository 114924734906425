import { gql } from '@apollo/client'
import { PARENTS_MEETING_BOOKING_FIELDS_FRAGMENT } from '../fragments'

/**
 * UNBOOK SLOT
 */
export const PARENTS_MEETING_BOOKING_UNBOOK_SLOT_MUTATION = gql`
  ${PARENTS_MEETING_BOOKING_FIELDS_FRAGMENT}

  mutation ($bookingId: ID!, $slotId: ID!) {
    deleteBookingSlot(input: { booking_id: $bookingId, slot_id: $slotId }) {
      ...BookingFields
    }
  }
`

/**
 * BOOK SLOT
 */
export const PARENTS_MEETING_BOOKING_BOOK_SLOT_MUTATION = gql`
  ${PARENTS_MEETING_BOOKING_FIELDS_FRAGMENT}

  mutation ($userId: ID!, $eventId: ID!, $lessonId: ID!, $studentId: ID!, $slotId: ID!, $quantity: Int!) {
    createBookingSlot(
      input: {
        user_id: $userId
        event_id: $eventId
        lesson_id: $lessonId
        student_id: $studentId
        slot_id: $slotId
        quantity: $quantity
      }
    ) {
      ...BookingFields
    }
  }
`

/**
 * SET MEETING TYPE
 */
export const PARENTS_MEETING_BOOKING_UPDATE_INVITATION = gql`
  mutation ($id: ID!, $input: UpdateInvitationInput!) {
    updateInvitation(id: $id, input: $input) {
      id
      meetingType {
        id
        name
        type
      }
    }
  }
`
