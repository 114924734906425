import { gql } from "@apollo/client"

const JITSI_CREATE_LOG_MUTATION = gql`
  mutation($input: CreateJitsiLogInput!) {
    createJitsiLog(input: $input) {
      id
			
      event {
				id
			}
			
      room_id
			
      user {
				id
				name
			}
			
      bookingSlot {
				id
			}
      
      type {
        id
        code
        description
      }

      agent_details
      payload
      ip_address
      created_at
      updated_at
    }
  }
`

export { JITSI_CREATE_LOG_MUTATION }