import React, { useEffect, useState } from 'react'
import { Badge } from '@components'
import { Typography } from 'antd'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import objectSupport from 'dayjs/plugin/objectSupport'
import PropTypes from 'prop-types'

dayjs.extend(duration)
dayjs.extend(objectSupport)

const secondsInDay = 60 * 60 * 24

const timerStatusTypes = {
  future: 'info',
  current: 'success',
  expired: 'danger',
  countdown: 'warning'
}

const VideoConferenceTimer = ({ startTime, endTime, autoDisconnect, handleAutoDisconnect }) => {
  const [timerDetails, setTimerDetails] = useState({ isReady: false })

  const getTimerDetails = (isReady = true) => {
    const now = dayjs()
    const secondsToStart = Math.floor(now.diff(startTime) / 1000)
    const secondsToEnd = Math.floor(now.diff(endTime) / 1000)
    const status = getStatus(secondsToStart, secondsToEnd)
    let duration = null
    let caption = null

    if (status.type === 'future') {
      duration = getDuration(secondsToStart)
      caption = duration.days > 0 ? null : 'Due to start in:'
    }

    if (status.type === 'expired') {
      duration = getDuration(secondsToEnd)

      if (duration.days === 0) {
        caption = autoDisconnect ? 'Expired' : 'Over running by:'
      }
    }

    if (status.type === 'current') {
      duration = getDuration(secondsToEnd)
      caption = autoDisconnect ? 'Call will terminate in:' : 'Time remaining:'
    }

    return {
      isReady,
      status,
      caption,
      duration,
      isCountingdownFinal: status.countdownFinal,
      type: status.countdown ? 'warning' : timerStatusTypes[status.type]
    }
  }
  
  useEffect(() => {
    if (timerDetails?.status?.type === 'expired' && autoDisconnect) {
      handleAutoDisconnect();
    }
  }, [timerDetails?.status?.type, autoDisconnect, handleAutoDisconnect]);
  
  useEffect(() => {
    setTimerDetails(getTimerDetails())

    const timer = setInterval(() => {
      setTimerDetails(getTimerDetails())
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [startTime, endTime, autoDisconnect]) // eslint-disable-line react-hooks/exhaustive-deps

  const getStatus = (secondsToStart, secondsToEnd) => {
    return {
      countdownFinal: (secondsToEnd < 0 && secondsToEnd >= -10) || (secondsToStart < 0 && secondsToStart >= -10),
      countdown: (secondsToEnd < 0 && secondsToEnd >= -30) || (secondsToStart < 0 && secondsToStart >= -30),
      type: secondsToStart < 0 ? 'future' : secondsToEnd >= 0 ? 'expired' : 'current'
    }
  }
  
  const getDuration = durationInSeconds => {
    const secondsRemaining = Math.abs(durationInSeconds) % secondsInDay

    return {
      days: Math.floor(Math.abs(durationInSeconds) / secondsInDay),
      time: dayjs({ seconds: secondsRemaining }).format('HH:mm:ss')
    }
  }

  const { duration: timerDuration, caption: timerCaption, type: timerType, isReady: isTimerReady, isCountingdownFinal, status } = timerDetails
  const expiredAutoDisconnect = status?.type === "expired" && autoDisconnect

  return (
    <Badge
      className={`sb-video-conference-modal__time ${
        isCountingdownFinal ? ' sb-video-conference-modal__time--countdown' : ''
      }`}
      type={timerType}
    >
      <Typography.Text strong>
        {startTime.format('H:mma')} - {endTime.format('H:mma')}
      </Typography.Text>

      {isTimerReady && (
        <div>
          {timerCaption}{' '}
          {!expiredAutoDisconnect && <Typography.Text strong>{timerDuration.time}</Typography.Text>}
        </div>
      )}
    </Badge>
  )
}

VideoConferenceTimer.propTypes = {
  autoDisconnect: PropTypes.bool,
  handleAutoDisconnect: PropTypes.func.isRequired,
  startTime: PropTypes.object.isRequired,
  endTime: PropTypes.object.isRequired,
}

export default VideoConferenceTimer