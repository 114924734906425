import React, { useEffect, useState } from 'react'
import { Alert } from '@components'
import { isSessionLive, getSecondsToSessionStart } from '@helpers'
import { Button, Typography } from 'antd'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import './SessionCountdownTimer.less'

const SessionCountdownTimer = ({ handleViewBookings, sessions }) => {
  const [hasLiveSession, setHasLiveSession] = useState(null)
  const [secondsToSessionStart, setSecondsToSessionStart] = useState(null)

  useEffect(() => {
    if (sessions && sessions.length > 0) {
      const updateSessionState = () => {
        setHasLiveSession(sessions.some(session => isSessionLive(session)));
        setSecondsToSessionStart(getSecondsToSessionStart(sessions));
      };

      updateSessionState(); // Run immediately
      const sessionCountdown = setInterval(updateSessionState, 1000);

      return () => clearInterval(sessionCountdown);
    }
  }, [sessions])

  return (
    <div className='sb-session-countdown-timer'>
      {!hasLiveSession && secondsToSessionStart !== null && secondsToSessionStart <= 60 * 60 * 24 && (
        <Alert
          className={`sb-session-countdown-timer__countdown ${
            secondsToSessionStart <= 60 ? 'sb-session-countdown-timer__countdown--pulse' : ''
          }`}
          type='info'
          title='Session is starting shortly'
          message={
            <div className='sb-session-countdown-timer__countdown-message'>
              <Typography.Paragraph color='white'>
                Session will begin in:{' '}
                <Typography.Text color='white' strong>
                  {dayjs({ seconds: secondsToSessionStart }).format('HH:mm:ss')}
                </Typography.Text>
              </Typography.Paragraph>
            </div>
          }
        >
          <Button onClick={() => handleViewBookings()} ghost size='large' shape='round'>
            View Bookings
          </Button>
        </Alert>
      )}
    
      {hasLiveSession && (
        <Alert
          className='sb-session-countdown-timer__live'
          type='warning'
          title='Event is Live'
          message={`Your event is currently live.`}
        >
          <Button onClick={() => handleViewBookings()} ghost size='large' shape='round'>
            View Bookings
          </Button>
        </Alert>
      )}
    </div>
  )
}

SessionCountdownTimer.propTypes = {
  handleViewBookings: PropTypes.func.isRequired,
  sessions: PropTypes.array.isRequired
}

export default SessionCountdownTimer