import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Form, Input, notification, Space, Typography } from 'antd'
import { CustomModal } from '@components'
import { COMMENTS_CREATE_MUTATION, COMMENTS_DELETE_MUTATION, COMMENTS_UPDATE_MUTATION } from '@graphql'
import PropTypes from 'prop-types'

import './CommentsModal.less'

const { Paragraph, Title } = Typography

const CommentsModal = ({ commentableId, commentableType, comment, event, handleCancel, intro, title }) => {
  const [updatedComment, setUpdatedComment] = useState(comment?.comment ?? "")
  const mode = comment !== null ? 'edit' : 'new'

  const [mutateCreateComment, createCommentMutation] = useMutation(COMMENTS_CREATE_MUTATION, {
    onError: error => {
      notification.error({
        message: 'Error',
        description: `There was an error creating this comment. Please try again.`
      })
    }
  })

  const [mutateDeleteComment] = useMutation(COMMENTS_DELETE_MUTATION)

  const [mutateUpdateComment, updateCommentMutation] = useMutation(COMMENTS_UPDATE_MUTATION, {
    onError: error => {
      notification.error({
        message: 'Error',
        description: `There was an error updating this comment. Please try again.`
      })
    }
  })

  const handleSaveComment = () => {
    if (mode === 'new' && updatedComment.length > 0) {
      mutateCreateComment({
        variables: {
          input: {
            event_id: event.id,
            commentable_type: commentableType,
            commentable_id: commentableId,
            comment: updatedComment,
            private: false
          }
        }
      })
    }

    if (mode === 'edit' && updatedComment.length > 0) {
      mutateUpdateComment({
        variables: {
          input: {
            id: comment.id,
            comment: updatedComment,
            private: false
          }
        }
      })
    }

    if (mode === 'edit' && updatedComment.length === 0 && comment?.id) {
      mutateDeleteComment({ variables: { id: comment.id } })
    }

    // check for errors first
    handleCancel()
  }

  return (
    <CustomModal
      className='sb-comments-modal'
      title={
        <div>
          <Title level={3}>{title}</Title>
          <Paragraph>{intro}</Paragraph>
        </div>
      }
      closable
      visible={true}
      onCancel={handleCancel}
      width='100%'
      footer={
        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button ghost onClick={() => handleCancel()} shape='round'>
            Cancel
          </Button>

          <Button
            onClick={() => handleSaveComment()}
            form='comments-form'
            type='primary'
            shape='round'
            loading={createCommentMutation.loading || updateCommentMutation.loading}
          >
            Save Comment
          </Button>
        </Space>
      }
    >
      <div className='sb-comments-modal__form'>
        <Paragraph className='sb-comments-modal__form-intro'>Please add / edit your comment below.</Paragraph>

        <Form id='comments-form'>
          <Form.Item
            name='comment'
            initialValue={updatedComment}
          >
            <Input.TextArea
              value={updatedComment}
              onChange={e => setUpdatedComment(e.target.value)}
              autoFocus
              rows={15}
              showCount
              maxLength={500}
            />
          </Form.Item>
        </Form>
      </div>
    </CustomModal>
  )
}

CommentsModal.propTypes = {
  commentableId: PropTypes.string.isRequired,
  commentableType: PropTypes.string.isRequired,
  comment: PropTypes.object,
  event: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  intro: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default CommentsModal
