import { gql } from '@apollo/client'
import { COMMENTS_FIELDS_FRAGMENT } from '../fragments'

/**
 * CREATE COMMENT
 */
export const COMMENTS_CREATE_MUTATION = gql`
  ${COMMENTS_FIELDS_FRAGMENT}

  mutation($input: CreateCommentInput!) {
    createComment(input: $input) {
      ...CommentFields
    } 
  }
`

/**
 * DELETE COMMENT
 */
export const COMMENTS_DELETE_MUTATION = gql`
  mutation($id: ID!) {
    deleteComment(id: $id) {
      id
    }
  }
`

/**
 * UPDATE COMMENT
 */
export const COMMENTS_UPDATE_MUTATION = gql`
  ${COMMENTS_FIELDS_FRAGMENT}

  mutation($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      ...CommentFields
    }
  }
`
