import React, { useState } from 'react'
import { Button } from 'antd'
import { Alert, Badge, EmptyMessage, InvitationCard, SessionCard, SessionCountdownTimer, SessionControls, SimpleCard } from '@components'
import { ReactComponent as Calendar } from '@assets/outstanding-invitations.svg'
import { isInvitationGroupBooked } from '@helpers'
import _groupBy from 'lodash/groupBy'
import _orderBy from 'lodash/orderBy'
import PropTypes from 'prop-types'

import './OutstandingInvitations.less'

const OutstandingInvitations = ({
  booking,
  invitation,
  event,
  handleChangeTab,
  handleSelectedSessionChange,
  handleSendItineraryEmail,
  handleInvitationGroupChange,
  outstandingInviteCount,
  selectedSessionId,
  sendItineraryEmailStatus
}) => {
  const [display, setDisplay] = useState('list')
  const { invitationGroups } = invitation
  const orderedInvitationGroups = _orderBy(invitationGroups, ['group.title'])

  const outstandingInvitationGroups = orderedInvitationGroups.filter(
    invitationGroup => !isInvitationGroupBooked(invitationGroup, booking)
  )

  const outstandingInvitationGroupsBySession = _groupBy(
    outstandingInvitationGroups,
    invitationGroup => invitationGroup.group.sessions[0].id
  )

  const isBookingsDisabled = event.disable_parent_bookings

  const { sessions } = event

  if (outstandingInviteCount === 0) {
    return (
      <SimpleCard color='quaternary' className='sb-outstanding-invites' title='Outstanding Invitations'>
        <EmptyMessage
          title='All appointments booked!'
          image={<Calendar />}
          description='You have no outstanding appointments. To view your bookings click the "Bookings" tab or the button below.'
        >
          <div className='sb-outstanding-invites__ctas'>
            <Button
              type='primary'
              className='cta'
              shape='round'
              size='large'
              onClick={() => handleChangeTab('schedule')}
            >
              View Bookings
            </Button>

            <Button
              type='primary'
              className='cta'
              shape='round'
              size='large'
              loading={sendItineraryEmailStatus.loading}
              onClick={handleSendItineraryEmail}
            >
              Email Schedule
            </Button>
          </div>
        </EmptyMessage>
      </SimpleCard>
    )
  }

  return (
    <SimpleCard
      color='quaternary'
      className='sb-outstanding-invites'
      title='Outstanding Invitations'
      strapline='Select a session to filter view and book your appointment time slot with the teacher below.'
      badge={
        <Badge size='medium' type='success'>
          {outstandingInviteCount}
        </Badge>
      }
    >
      <SessionCountdownTimer sessions={sessions} handleViewBookings={() => handleChangeTab('schedule')} />

      {!isBookingsDisabled && event.max_bookings_per_student > 0 && (
        <Alert
          type='info'
          title='Restricted Bookings'
          message={`Bookings for this event have been restricted to ${event.max_bookings_per_student} per student`}
        />
      )}

      {isBookingsDisabled && (
        <Alert
          type='warning'
          title='Bookings Disabled'
          message='Bookings for this event have currently been disabled by your event administrator.'
        />
      )}

      <SessionControls
        currentDisplay={display}
        currentSession={selectedSessionId}
        handleSetDisplay={view => setDisplay(view)}
        handleSessionChange={sessionId => handleSelectedSessionChange(sessionId)}
        sessions={sessions}
      />

      {sessions
        .filter(session => session.id === selectedSessionId || selectedSessionId === 'all')
        .map(session => {
          const invitationGroups = outstandingInvitationGroupsBySession[session.id] || null

          if ((!invitationGroups || invitationGroups.length === 0) && selectedSessionId === 'all') return null

          if (selectedSessionId !== 'all' && (!invitationGroups || invitationGroups.length === 0)) {
            return (
              <EmptyMessage
                title='All session slots booked'
                image={<Calendar />}
                description='Congratulations! All slots for this session have been booked.'
              >
                <Button type='primary' size='large' shape='round' onClick={() => handleSelectedSessionChange('all')}>
                  Remove Session Filters
                </Button>
              </EmptyMessage>
            )
          }

          return (
            <div className='sb-outstanding-invites__sessions' key={session.id}>
              <SessionCard session={session} noOfAppointments={invitationGroups.length}>
                <div
                  className={`sb-outstanding-invites__slots ${
                    display === 'grid' ? 'sb-outstanding-invites__slots--grid' : ''
                  }`}
                >
                  {invitationGroups.map(invitationGroup => (
                    <InvitationCard
                      key={invitationGroup.id}
                      booking={booking}
                      event={event}
                      invitation={invitation}
                      invitationGroup={invitationGroup}
                      handleInvitationGroupChange={invitationGroup => handleInvitationGroupChange(invitationGroup)}
                      session={session}
                    />
                  ))}
                </div>
              </SessionCard>
            </div>
          )
        })}
    </SimpleCard>
  )
}

OutstandingInvitations.propTypes = {
  booking: PropTypes.object,
  event: PropTypes.object.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
  handleSelectedSessionChange: PropTypes.func.isRequired,
  handleSendItineraryEmail: PropTypes.func.isRequired,
  handleInvitationGroupChange: PropTypes.func.isRequired,
  invitation: PropTypes.object.isRequired,
  sendItineraryEmailStatus: PropTypes.object.isRequired,
  selectedSessionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default OutstandingInvitations
